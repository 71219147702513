// for global cursor only
#globalCursor {
    z-index: 999900;
    position: absolute;
    .cursor {
        will-change: transform;
        //-webkit-transform: translateZ(0);
        //transform: translateZ(0);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        z-index: 999900;
        pointer-events: none;
        position: fixed;
        padding: 1px;
        top: auto;
        left: auto;
        color: rgba(255,0,255, 0.9);

        min-width: 22px;
        width: 22px;
        min-height: 22px;
        height: 22px;
        border-radius: 50%;
        box-shadow: none !important;
        &.fill {
            background: rgba(255,0,255, 0.9);
            //-webkit-transform: translate(-50%, -50%);
            //transform: translate(-50%, -50%);
        }
        .circle {
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            min-width: 20px;
            width: 20px;
            min-height: 20px;
            height: 20px;
            -webkit-box-shadow: 0 0 0 1px $baad-main-color;
            box-shadow: 0 0 0 1px $baad-main-color;
            border-radius: 50%;

            .content {
                -webkit-transform-origin: left top 0;
                -ms-transform-origin: left top 0;
                -o-transform-origin: left top 0;
                transform-origin: left top 0;
                line-height: 1.2;
                height: 100%;
                position: relative;
                padding: 12px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 376px) {
    #globalCursor {
        display: none;
    }
}
