@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

.artistCategory-page {
  .artistBarsStyle {
    border-style: solid;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    border-color: $baad-main-color;
    .vertical-line {
      border-style: solid;
      border-color: $baad-main-color;
      border-top: none;
      border-left: none;
      border-bottom: none;
      height: 100%;
    }
  }

  /* page before animation */
  .animation-content{
    display: flex;
    overflow: hidden;
    height: 100%;
    .lines{
      //width: calc(100vw - 24px);
      width: calc(100vw - 2*#{$baad-main-padding});
      flex: 0 0 100%;
      transition: all 1s;
    }
  }

  /* for tooltip title */
  div.tip{
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 0;
      transform: translateX(-50%);
      padding: 0 6px;
    }
    &:hover {
      span {
        display: block;
        z-index: 100;
        background-color: white;
        position: absolute;
        font-size: 28px;
        white-space: nowrap;
      }
    }
    span {
      display: none;
      text-transform: capitalize;
    }
  }

  #showme {
    transition: all 2s;
  }

}

//TEXT/FONTS:
.artistCategory-page {
  div.tip:hover span {
    //@include baad-text(28px, 31px, 0);
    @include baad-text(42px, 42px, 0);
    text-transform: uppercase;
    margin-top: -13px;
  }

}




