.mobile-overlay {
  position: fixed;
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
  opacity: 0.9;
  min-height: 100vh;
  width: 100%;
  padding: 100px 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.mobile-overlay__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Akkurat Mono', sans-serif;
  text-align: center;
  color: #0038FF;
}

/* Icons */
.fa {
  font-style: normal;
}
.fa-arrows {
  font-family: 'Akkurat Mono', sans-serif;
  font-weight: 400;
  font-size: 40px;
  color: #0038FF;
}
.fa-circle {
  border: 1px solid #0038FF;
  border-radius: 26px;
  width: 42px;
  height: 42px;
  display: grid;
  place-content: center;
  margin-top: 40px;
  margin-bottom: 18px;
  img {
    margin-left: 1px;
  }
}

/* Media Queries */
@media screen and (min-width: 736px) {
  .mobile-overlay {
    display: none;
  }
}
