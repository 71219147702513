.cursor-guide-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 22px;
    left: 36px;
    z-index: 1;
    background-color: #F0F0F0;
    height: 45px;
    border: 1px solid #9D9D9D;
    font-size: 16px;
    color: #9D9D9D;
    text-transform: uppercase;
    font-family: Akkurat;
    cursor: none;

    .guide__left {
        padding: 0 36px;

    }

    .guide__right {
        padding: 0 18px;
        background-color: #F26C6C;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
}