.human-post-form {
  opacity: 0;
  pointer-events: none;
  height: 0;
  transition: opacity 0.3s ease-in-out;
}
.human-post-form--active {
  opacity: 1;
  pointer-events: auto;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}

.contact-form__heading {
  font-family: "Akkurat Light";
  margin: 0;
}

.contact-body__subtitle {
  font-family: "Akkurat Mono";
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
}

.contact-body__text {
  font-family: "Akkurat Mono";
  color: #9D9D9D;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 17px;
}

.step3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  img {
    width: 143.77px;
    height: 141.74px;
    margin: 0 auto 70px;
  }
}

.form-alert {
  display: block;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 15px;
}

.input-radio-wrapper {
  display: flex;
  margin-bottom: 30px;
}
.input-radio {
  display: flex;
  align-items: center;
  input {
    margin: 0 10px 0 0;
    height: 14px;
    width: 14px;
    position: relative;
    -webkit-appearance: none;
    background-color: #F0F0F0;
    border: 1px dashed #9D9D9D;
    border-radius: 100%;
    &:checked  {
      border: 1px dashed #000000;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        margin: auto;
        background: #000000;
        border-radius: 50%;
        border: 1px solid #F0F0F0;
      }
      ~ .input-radio__label {
        color: #000000;
      }
    }
  }
}
.input:first-child .input-radio {
  margin-right: 34px;
}
.input-radio__label {
  font-family: "Akkurat Mono";
  text-transform: uppercase;
  color: #9D9D9D;
  font-weight: 400;
  margin: 1px 0 0;
  font-size: 13px;
  cursor: pointer;
}
.input--outlined {
  outline: 0;
  font-family: "Akkurat Mono";
  background: none;
  border: 0;
  border-bottom: 1px solid #9D9D9D;
  width: 100%;
  padding: 5px 0;
  margin-bottom: 15px;
  height: 30px;
  color: #9D9D9D;
  text-transform: uppercase;
  font-size: 14px;

  &::placeholder {
    color: #9D9D9D;
  }
}

.textarea--outlined {
  height: 180px;
  line-height: 40px;
  &::placeholder {
    line-height: 40px;
  }
}

.art-details {
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  &:after {
    position: absolute;
    content: '—';
    left: 0;
    bottom: 0;
  }
}
.art-details__img-wrapper {
  width: 95px;
  height: 62px;
  overflow: hidden;
  margin-right: 35px;
}
.art-details__img {
  width: 100%;
  height: auto;
}
.art-details__text {
  font-family: "Akkurat Mono";
  font-size: 13px;
  line-height: 16.5px;
  color: #000000;
  margin-bottom: 2px;
  text-transform: uppercase;
  h1 {
    font-size: 13px;
    line-height: 16.5px;
  }
  span {
    color: #0038FF;
    margin-left: 15px;
  }
  &:last-child {
    margin-top: 10px;
  }
}

.contact-footer {
  padding-bottom: 80px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    display: block;
    margin-left: auto;
  }
}

.form-hidden {
  visibility: hidden;
  height: 0;
}

@media screen and (min-width: 992px) {
  .contact-form__heading {
    font-size: 3.125vw;
    line-height: 2.813vw;
  }
  .art-details-wrapper {
    display: none;
  }
  .input-radio {
    input {
      margin: 0 22px 0 0;
      height: 23px;
      width: 23px;
    }
  }
  .contact-body__subtitle,
  .contact-body__text,
  .input--outlined,
  .input-radio__label {
    font-size: 1.146vw;
    line-height: 1.563vw;
  }
  .input-radio-wrapper {
    margin-bottom: 43px;
  }
  .contact-footer {
    margin-top: 75px;
    padding-bottom: 20px;
  }
}
