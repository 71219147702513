// Variables:
//$baad-main-padding: 10px;
$baad-main-padding: 25px;
//$baad-bottom-padding: 58px;
$baad-bottom-padding: 5.938vw;
$baad-footer-header-padding: 8px;
$baad-main-color: #000000;
$baad-half-opacity: 0.55;
$baad-main-border: 2px solid black;
//$baad-ha-border: 2px solid black;
$baad-ha-border: 1px solid black;
