@font-face {
  font-family: "LL Akkurat Pro Regular Web";
  src: url("../fonts/lineto-akkurat-pro-regular-13c5d3fb0f3f0f4a2dbe81b55b9b2586.eot");
  src: url("../fonts/lineto-akkurat-pro-regular-13c5d3fb0f3f0f4a2dbe81b55b9b2586.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-akkurat-pro-regular-0241e83626b4a989366fd594626ff076.woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LL Akkurat Pro Regular " Sub;
  src: url("../fonts/lineto-akkurat-pro-regular-e2c79c55a3d8e3008646082c6509929e.eot");
  src: url("../fonts/lineto-akkurat-pro-regular-e2c79c55a3d8e3008646082c6509929e.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-akkurat-pro-regular-0f24c307957d9a6513f3a80c452857ad.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LL Akkuratmono Pro Regular Web";
  src: url("../fonts/lineto-akkuratmono-pro-regular-3aa3032de5c0fe5152a40a50d12e621c.eot");
  src: url("../fonts/lineto-akkuratmono-pro-regular-3aa3032de5c0fe5152a40a50d12e621c.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-akkuratmono-pro-regular-ac9fbaba52c55705c439ab4a41739352.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LL Akkuratmono Pro Regular" Sub;
  src: url("../fonts/lineto-akkuratmono-pro-regular-b48dc1527b82ee9513b3c2deff1b504a.eot");
  src: url("../fonts/lineto-akkuratmono-pro-regular-b48dc1527b82ee9513b3c2deff1b504a.eot?#iefix") format("embedded-opentype"), url("../fonts/lineto-akkuratmono-pro-regular-ca30f67bf68b4d022686179b109b3367.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat";
  src: url("../fonts/Akkurat.ttf");
  src: url("../fonts/Akkurat.ttf?#iefix") format("truetype"), url("../fonts/Akkurat.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Akkurat Light";
  src: url("../fonts/Akkurat-Light.ttf");
  src: url("../fonts/Akkurat-Light.ttf?#iefix") format("truetype"), url("../fonts/Akkurat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat Beta Regular";
  src: url("../fonts/AkkuratBETA-Regular.eot");
  src: url("../fonts/AkkuratBETA-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AkkuratBETA-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat Mono";
  src: url("../fonts/Akkurat-Mono.eot");
  src: url("../fonts/Akkurat-Mono.eot?#iefix") format("embedded-opentype"), url("../fonts/Akkurat-Mono.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
/*---- Up enter and exit -----*/
.enter-Up.page-enter {
  animation: slideInUp 1s forwards;
}

.exit-Up.page-exit {
  animation: slideOutUp 1s forwards;
}

/*---- Down enter and exit -----*/
.enter-Down.page-enter {
  animation: slideInDown 1s forwards;
}

.exit-Down.page-exit {
  animation: slideOutDown 1s forwards;
}

/*---- Right enter and exit -----*/
.enter-Right.page-enter {
  animation: slideInRight 1s forwards;
  animation-timing-function: "cubic-bezier(.23,0,0.4,0)";
}

.exit-Right.page-exit {
  animation: slideOutRight 1s forwards;
}

/*---- Left enter and exit -----*/
.enter-Left.page-enter {
  animation: slideInLeft 1s forwards;
  animation-timing-function: "cubic-bezier(.23,0,0.4,0)";
}

.exit-Left.page-exit {
  animation: slideOutLeft 1s forwards;
}

/*---- page transition key frames ---- */
@keyframes slideInLeft {
  from {
    transform: translate(-100%, 0);
    visibility: visible;
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    transform: translate(0, 0);
  }
  to {
    visibility: hidden;
    transform: translate(-100%, 0);
  }
}
@keyframes slideInRight {
  from {
    transform: translate(100%, 0);
    visibility: visible;
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slideOutRight {
  from {
    transform: translate(0, 0);
  }
  to {
    visibility: hidden;
    transform: translate(100%, 0);
  }
}
@keyframes slideInUp {
  from {
    transform: translateY(100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideOutUp {
  from {
    transform: translate(0, 0);
  }
  to {
    visibility: hidden;
    transform: translate(0, -100%);
  }
}
@keyframes slideInDown {
  from {
    transform: translate(0, -100%);
    visibility: visible;
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slideOutDown {
  from {
    transform: translateY(0);
  }
  to {
    visibility: hidden;
    transform: translateY(calc(100% - 60px));
  }
}
/* page transition key frames end*/
#globalCursor {
  z-index: 999900;
  position: absolute;
}
#globalCursor .cursor {
  will-change: transform;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 999900;
  pointer-events: none;
  position: fixed;
  padding: 1px;
  top: auto;
  left: auto;
  color: rgba(255, 0, 255, 0.9);
  min-width: 22px;
  width: 22px;
  min-height: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: none !important;
}
#globalCursor .cursor.fill {
  background: rgba(255, 0, 255, 0.9);
}
#globalCursor .cursor .circle {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  -webkit-box-shadow: 0 0 0 1px #000000;
  box-shadow: 0 0 0 1px #000000;
  border-radius: 50%;
}
#globalCursor .cursor .circle .content {
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  -o-transform-origin: left top 0;
  transform-origin: left top 0;
  line-height: 1.2;
  height: 100%;
  position: relative;
  padding: 12px;
  width: 100%;
}

@media screen and (max-width: 376px) {
  #globalCursor {
    display: none;
  }
}
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  outline: 1px solid #000000;
}

@media (min-width: 768px) {
  .whatever-class {
    /*96 means 9.6 bootstrap grid columns*/
    /*24 means 2.4 bootstrap grid columns*/
  }
  .whatever-class .col-md-96 {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .whatever-class .col-md-24 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 992px) {
  .whatever-class {
    /*96 means 9.6 bootstrap grid columns*/
    /*24 means 2.4 bootstrap grid columns*/
  }
  .whatever-class .col-lg-96 {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .whatever-class .col-lg-24 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.panels-item {
  top: 0;
  z-index: 1;
  background: #f0f0f0;
  height: 100%;
}

.panels-item--two {
  left: 0;
  border-right: 1px solid black;
}

.panels-item--three {
  right: 0;
  border-left: 1px solid black;
}

.action-panel {
  z-index: 7 !important;
}

.row {
  margin: 0 !important;
  height: 100%;
  overflow-x: hidden;
}

[class^=col] {
  height: auto;
}

.headerDiv {
  padding-bottom: 5.938vw;
  height: 100vh;
  overflow: hidden;
  line-height: 1.2;
  transition: padding 0.5s;
}

.headerDiv-artist {
  padding: 5.938vw 0 0;
}

@media screen and (max-width: 600px) {
  .headerDiv {
    padding: 0;
    height: calc(100vh - 97px);
  }
  .row {
    overflow-x: visible;
  }
}
@media screen and (max-width: 992px) {
  .headerDiv-artist {
    padding: 97px 0 0;
  }
}
@media screen and (min-width: 1920px) {
  .headerDiv {
    padding-bottom: 5.938vw;
  }
  .headerDiv-artist {
    padding: 5.938vw 0 0;
  }
}
.text-uppercase {
  text-transform: uppercase;
}

.aTagStyle {
  text-decoration: none;
  margin-bottom: 5px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  border-color: #000000;
  color: #000000;
  background: #000000;
  background-color: #000000;
}

a {
  cursor: none;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.page {
  overflow: hidden;
  position: fixed;
  height: 100vh;
  top: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  background: #f0f0f0;
}

.width-fit-content {
  width: fit-content;
}

.contentHidden {
  visibility: hidden;
}

.show-item {
  display: block !important;
}
.show-item.tooltip--hidden {
  display: none !important;
}

.hide-item {
  display: none !important;
}

.hide-item-opacity {
  opacity: 0;
  width: 0 !important;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.opacity-0 {
  opacity: 0;
  pointer-events: none;
}

.opacity-1 {
  opacity: 1;
}

@media screen and (min-width: 500px) {
  .layout-lock {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.m0 {
  margin: 0px;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m40 {
  margin: 40px;
}

.m5p {
  margin: 5%;
}

.mx5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx5p {
  margin-left: 5%;
  margin-right: 5%;
}

.my0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my5p {
  margin-top: 5%;
  margin-bottom: 5%;
}

.mt0 {
  margin-top: 0px;
}

.mt5 {
  margin-top: 5px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt55 {
  margin-top: 55px;
}

.mt60 {
  margin-top: 60px;
}

.mt5p {
  margin-top: 5%;
}

.mb0 {
  margin-bottom: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb55 {
  margin-bottom: 55px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb5p {
  margin-bottom: 5%;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml45 {
  margin-left: 45px;
}

.ml50 {
  margin-left: 50px;
}

.ml5p {
  margin-left: 5%;
}

.mr0 {
  margin-right: 0px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr35 {
  margin-right: 35px;
}

.mr40 {
  margin-right: 40px;
}

.mr45 {
  margin-right: 45px;
}

.mr50 {
  margin-right: 50px;
}

.mr5p {
  margin-right: 5%;
}

.p0 {
  padding: 0px;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p12 {
  padding: 12px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p25 {
  padding: 25px;
}

.p45 {
  padding: 45px;
}

.p50 {
  padding: 50px;
}

.p100 {
  padding: 100px;
}

.px2 {
  padding-left: 2px;
  padding-right: 2px;
}

.px10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px25 {
  padding-left: 25px;
  padding-right: 25px;
}

.px30 {
  padding-left: 30px;
  padding-right: 30px;
}

.px35 {
  padding-left: 35px;
  padding-right: 35px;
}

.px40 {
  padding-left: 40px;
  padding-right: 40px;
}

.px55 {
  padding-left: 55px;
  padding-right: 55px;
}

.px65 {
  padding-left: 65px;
  padding-right: 65px;
}

.px70 {
  padding-left: 70px;
  padding-right: 70px;
}

.px10p {
  padding-left: 10%;
  padding-right: 10%;
}

.py0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pt0 {
  padding-top: 0px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pt35 {
  padding-top: 35px;
}

.pt40 {
  padding-top: 40px;
}

.pt45 {
  padding-top: 45px;
}

.pt50 {
  padding-top: 50px;
}

.pt55 {
  padding-top: 55px;
}

.pt70 {
  padding-top: 70px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb45 {
  padding-bottom: 45px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb70 {
  padding-bottom: 70px;
}

.pr0 {
  padding-right: 0px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr12 {
  padding-right: 12px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr55 {
  padding-right: 55px;
}

.pr70 {
  padding-right: 70px;
}

.pl0 {
  padding-left: 0px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl35 {
  padding-left: 35px;
}

.pl40 {
  padding-left: 40px;
}

.pl70 {
  padding-left: 70px;
}