@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote( ( $y / $x ) * 100 + '%' );
	position: relative;	

	@if $pseudo {
		&::before {
			@include pseudo($pos: relative);
			//width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}

	img, iframe {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	img {
		object-fit: contain;
	}
}


.cursor-local__wrapper {
    position: absolute;
    left: -9px;
    top: -9px;
}

.message-input {
    margin-left: 20px;
    margin-top: -10px;
    margin-bottom: 16px;
    height: 30px;

    input {
        margin:0;
        border:0;
        padding:0;
        display:inline-block;
        vertical-align:middle;
        white-space:normal;
        background:none;
        line-height:1;
        background-color: #2E75E4;
        color: #D1E3FF;
        font-family: Akkurat;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.01em;
        text-align: left;
        padding: 6px 12px;
        margin-bottom: 0px;
        transition: all 250ms;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px 4px 4px 4px;

        &:focus
        {
            outline:0;
        }
    }
}

.guide-dummy {
    margin-left: 20px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    border-radius: 7px;
    width: 240px;
    overflow: hidden;
    @include responsive-ratio(720, 480, true);
    margin-bottom: 20px;
    position: relative;
    border: 3px solid black;
}

.messages__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: -10px;

    .message {
        background-color: #2E75E4;
        color: #D1E3FF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px 4px 4px 4px;
        font-family: Akkurat;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.01em;
        text-align: left;
        padding: 6px 12px;
        margin-bottom: 8px;
        transition: all 250ms;
        max-width: 180px;
        opacity: 1;
        //line-break: anywhere;
        transition: opacity 500ms;

        &.message_visibility__hidden {
            opacity: 0;
        }
    }

    .message-local {
        background-color: white;
        color: #848484;
        border: 1px solid rgba(132, 132, 132, 1);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px 4px 4px 4px;        
    }
}

.user-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > span {
        margin-bottom: 0;
    }

    .user-icon {
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin: 0 5px;
    }
}