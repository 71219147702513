::-webkit-scrollbar {
    width: 0.5em;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ffffff;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: $baad-main-color;
    outline: 1px solid $baad-main-color;
  }