@font-face {
    font-family: "LL Akkurat Pro Regular Web";
    src: url("../fonts/lineto-akkurat-pro-regular-13c5d3fb0f3f0f4a2dbe81b55b9b2586.eot");
    src: url("../fonts/lineto-akkurat-pro-regular-13c5d3fb0f3f0f4a2dbe81b55b9b2586.eot?#iefix") format("embedded-opentype"),
      url("../fonts/lineto-akkurat-pro-regular-0241e83626b4a989366fd594626ff076.woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "LL Akkurat Pro Regular "Sub;
    src: url("../fonts/lineto-akkurat-pro-regular-e2c79c55a3d8e3008646082c6509929e.eot");
    src: url("../fonts/lineto-akkurat-pro-regular-e2c79c55a3d8e3008646082c6509929e.eot?#iefix") format("embedded-opentype"),
      url("../fonts/lineto-akkurat-pro-regular-0f24c307957d9a6513f3a80c452857ad.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "LL Akkuratmono Pro Regular Web";
    src: url("../fonts/lineto-akkuratmono-pro-regular-3aa3032de5c0fe5152a40a50d12e621c.eot");
    src: url("../fonts/lineto-akkuratmono-pro-regular-3aa3032de5c0fe5152a40a50d12e621c.eot?#iefix") format("embedded-opentype"),
      url("../fonts/lineto-akkuratmono-pro-regular-ac9fbaba52c55705c439ab4a41739352.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "LL Akkuratmono Pro Regular"Sub;
    src: url("../fonts/lineto-akkuratmono-pro-regular-b48dc1527b82ee9513b3c2deff1b504a.eot");
    src: url("../fonts/lineto-akkuratmono-pro-regular-b48dc1527b82ee9513b3c2deff1b504a.eot?#iefix") format("embedded-opentype"),
      url("../fonts/lineto-akkuratmono-pro-regular-ca30f67bf68b4d022686179b109b3367.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }

@font-face {
  font-family: "Akkurat";
  src: url("../fonts/Akkurat.ttf");
  src: url("../fonts/Akkurat.ttf?#iefix") format("truetype"),
  url("../fonts/Akkurat.ttf") format("truetype");
  font-style: normal;
}

  @font-face {
    font-family: "Akkurat Light";
    src: url("../fonts/Akkurat-Light.ttf");
    src: url("../fonts/Akkurat-Light.ttf?#iefix") format("truetype"),
    url("../fonts/Akkurat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Akkurat Beta Regular";
    src: url("../fonts/AkkuratBETA-Regular.eot");
    src: url("../fonts/AkkuratBETA-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AkkuratBETA-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Akkurat Mono";
    src: url("../fonts/Akkurat-Mono.eot");
    src: url("../fonts/Akkurat-Mono.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Akkurat-Mono.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
