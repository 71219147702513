.navigation--mobile {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: #F0F0F0;
  bottom: 0;
  overflow: hidden;
}
.navigation {
  visibility: hidden;
  pointer-events: none;
  transform: translateY(100%);
  transition: transform 2s ease-in-out, visibility 2s ease-in-out;
  &--active {
    visibility: visible;
    pointer-events: unset;
    transform: translateY(0);
  }
}
.navigation--desktop {
  display: none;
}
.navigation__item {
  padding: 32px;
  background: #F0F0F0;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
}
.navigation__header {
  display: flex;
  align-items: center;
}
.navigation__body {
  padding: 0 26px;
}
.navigation__dot {
  display: block;
  margin-right: 20px;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: #000000;
  &--active {
    background: #0038FF;
  }
}
.navigation__title {
  font-family: "Akkurat Mono";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 19px;
  max-width: 200px;
  margin: 0;
}
.navigation__img {
  max-height: 265px;
  margin-top: 25px;
  img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 265px;
  }
}
.navigation__btn {
  font-family: "Akkurat Mono";
  color: #9D9D9D;
  font-size: 14px;
  line-height: 19px;
  border: 0;
  padding: 0;
  background: none;
  text-decoration: underline;
  margin-top: 27px;
  margin-left: 20px;
}
.source__list {
  list-style: none;
  margin: 0;
  overflow: auto;
  height: calc(100vh - 300px);
  padding: 0 0 70px;
}
.source__item {
  font-family: "Akkurat Mono";
  padding: 20px 37px;
  border-bottom: 1px dashed #9D9D9D;
  display: flex;
  align-items: center;
  color: #9D9D9D;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}
.source__dot {
  display: block;
  width: 16px;
  height: 16px;
  background: #5D81FF;
  border-radius: 100%;
  margin-right: 24px;
}
.source__btn {
  font-family: Akkurat;
  margin-left: auto;
  border: 0;
  padding: 0;
  background: none;
  font-size: 22px;
  line-height: 19px;
  margin-top: -10px;
}
.navigation__footer {
  background: #F0F0F0;
  height: 69px;
  position: fixed;
  padding: 15px 30px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn--prev,
.btn--next {
  font-family: "Akkurat Mono";
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #9D9D9D;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
}
.btn--next {
  span {
    margin-left: 14px;
  }
}
.btn--prev {
  span {
    display: inline-block;
    padding-left: 14px;
    transform: rotate(-180deg);
  }
}
.navigation-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99;
  top: 0;
  bottom: 0;
  padding: 32px;
  overflow-y: auto;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .navigation-overlay {
    background: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}
.navigation-overlay__img {
  width: 100%;
  margin-bottom: 28px;
  img {
    display: block;
    max-width: 100%;
    max-height: 305px;
    object-fit: contain;
    margin: 0 auto;
  }
}
.navigation-overlay__title-block {
  display: flex;
}
.navigation-overlay__text {
  font-family: "Akkurat Mono";
  font-weight: 400;
  font-size: 1.146vw;
  line-height: 1.563vw;
  color: #5D81FF;
}
.navigation-overlay__link {
  font-family: "Akkurat Mono";
  font-weight: 600;
  font-size: 0.8vw;
  color: #5D81FF;
  cursor: pointer;
  margin-left: 0.521vw;
}
.navigation-overlay__btn {
  margin-left: auto;
  margin-bottom: 27px;
  display: block;
}

.m-b-0 {
  margin-bottom: 0;
}

.navigation__arrow {
  font-family: "Akkurat Light";
  font-weight: 500;
  font-size: 22px;
  line-height: 19px;
  transform: rotate(270deg);
  position: absolute;
  top: 29px;
  right: 26px;
  color: #000000;
}

.item {
  opacity: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  &--active {
    visibility: visible;
    opacity: 1;
    height: 100vh;
  }
}

.slideInLeft {
  animation: slideInLeft 0.3s forwards;
}

.slideInRight {
  animation: slideInRight 0.3s forwards;
}

@keyframes slideInLeft {
  from {
    transform: translate(-100%, 0);
    visibility: visible;
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes slideInRight {
  from {
    transform: translate(100%, 0);
    visibility: visible;
  }

  to {
    transform: translate(0, 0);
  }
}

@media screen and (min-width: 480px) {
  .navigation--mobile {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .navigation--desktop {
    position: absolute;
    z-index: 1;
    width: 100%;
    background: #F0F0F0;
    bottom: 0;
    height: 85%;
    display: block;
    overflow: hidden;
  }
  .navigation {
    transform: translateY(calc(100% - 5.938vw));
    &--active {
      transform: translateY(0);
    }
  }
  .navigation__header {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.938vw;
    padding: 0 1.875vw;
    div {
      display: flex;
      align-items: center;
    }
  }
  .navigation__wrapper {
    display: grid;
    grid-template-columns: 1fr 33%;
    height: calc(100% - 5.938vw);
  }
  .navigation-col {
    overflow: auto;
    &:first-child {
      display: grid;
      padding: 1.25vw 1.875vw;
    }
    &:last-child {
      border-left: 1px solid #000000;
      padding: 1.875vw;
    }
  }
  .source__title {
    color: #000000;
  }
  .source__description {
    color: #9D9D9D;
    &:before,
    &:after {
      content: '—';
      color: #000000;
      display: block;
    }
    &:before {
      margin-top: 20px;
      margin-bottom: 40px;
    }
    &:after {
      margin-top: 100px;
    }
    p:first-child {
      margin-bottom: 40px;
    }
  }
  .source__title,
  .source__description {
    font-family: "Akkurat Mono";
    font-size: 22px;
    line-height: 30px;
    text-transform: uppercase;
  }
  .source__img {
    margin-top: 35px;
    max-height: 397px;
    img {
      max-width: 100%;
      filter: brightness(0%);
      display: block;
      height: 100%;
      max-height: 397px;
      object-fit: contain;
    }
    &--active {
      img {
        filter: brightness(100%);
      }
    }
  }
  .source__btn {
    font-family: "Akkurat Mono";
    font-size: 22px;
    line-height: 30px;
    color: #9D9D9D;
    margin-top: 1vw;
    text-decoration: underline;
  }
  .constellation {
    position: relative;
    width: 100%;
    max-width: 1075px;
    height: 100%;
    max-height: 500px;
    margin: auto;
    z-index: 0;
  }
  .planet {
    cursor: pointer;
    position: absolute;
    border-radius: 100%;
    background: #000000;
    width: 30px;
    height: 30px;
    &:not(.planet--active):hover {
      transform: scale(1.3);
      transition: all 0.3s;
    }
    &--active {
      background: #0038FF;
      &.planet--previous {
        animation: pulse 2s infinite;
      }
    }
    &--hidden {
      visibility: hidden;
    }
    &--previous {
      animation: pulsePrevious 5s infinite;
    }
  }
  .star {
    cursor: pointer;
    position: absolute;
    background: #000000;
    width: 11.5px;
    height: 12px;
    border-radius: 100%;
    left: 8px;
    top: 8px;
    z-index: -1;
    &--active, &:hover {
      background: #5D81FF;
      transition: background 0.3s ease-in-out, transform 0.3s;
      transform: scale(1.95);
    }
  }
  .navigation__arrow {
    position: unset;
    cursor: pointer;
    font-weight: 300;
    font-size: 3.125vw;
    transform: rotate(-90deg);
    margin: auto 0;
  }
  .navigation-overlay {
    display: none;
  }
  .line,
  .line--planet {
    position: absolute;
    z-index: -2;
    pointer-events: none;
  }
  .line {
    width: 1px;
    height: 1px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMxIiBoZWlnaHQ9IjUiIHZpZXdCb3g9IjAgMCAxMzEgNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMjUxNzgzIDMuODM1NUwxMzAuMjE3IDAuNzY1NjI1IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjAuNzUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWRhc2hhcnJheT0iMSA0Ii8+Cjwvc3ZnPgo=")
  }
  .line--planet {
    background: #000000;
    width: 1px;
  }
}

@media screen and (min-width: 1025px) {
  .navigation-overlay__img {
    max-height: 397px;
    img {
      display: block;
      max-width: 100%;
      height: 100%;
      max-height: 397px;
      object-fit: contain;
    }
  }
  .navigation-page__bg {
    background: rgba(0, 0, 0, 0);
    position: fixed;
    width: 100vw;
    height: 100%;
    bottom: 0;
    cursor: pointer;
    transform: translateY(100%);
    &--active {
      transform: translateY(0);
    }
  }
  .navigation__title {
    font-family: "Akkurat Light";
    display: flex;
    align-items: flex-start;
    font-weight: 300;
    max-width: 100%;
    span {
      font-family: "Akkurat Mono";
      font-weight: 400;
    }
  }
  .navigation-form__wrapper {
    display: grid;
    grid-template-columns: 185px 1fr;
    gap: 0;
    align-items: center;
    margin-top: 30px;
  }
  .navigation-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-column: 1/3;
    margin-bottom: 3vh;
    align-items: center;
  }
  .navigation-form__label {
    font-family: Akkurat;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 0;
    font-size: 1.1vw;
    line-height: 3.2vh;
    margin-right: 1vw;
    &::after {
      content: '≥';
      margin-left: 5px;
    }
  }
  .navigation-form__input {
    font-family: Akkurat;
    font-weight: 400;
    color: #9D9D9D;
    background: none;
    border: 0;
    border-bottom: 1px solid #9D9D9D;
    outline: none;
    flex: 1;
    padding: 0;
    font-size: 1.1vw;
    line-height: 3.2vh;
  }
  .navigation-form__button {
    font-weight: 400;
    line-height: 15px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    background: rgba(240, 240, 240, 0.8);
    border: 1px dashed #9D9D9D;
    color: #9D9D9D;
    font-size: 1.25vw;
    width: 9vw;
    height: 5vh;
    &:hover {
      border: 1px dashed #000000;
      backdrop-filter: blur(30px);
      color: #000000;
    }
  }
}
@media screen and (min-width: 1300px) {
  .navigation__title {
    font-size: 3.125vw;
    line-height: 2.813vw;
    span {
      font-size: 1.146vw;
      line-height: 1.563vw;
      margin-left: 20px;
    }
  }
  .source__title,
  .source__description,
  .source__btn {
    font-size: 16.5px;
    line-height: 22.5px;
  }
  .source__title {
    max-width: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .navigation-overlay__text {
    font-size: 12px;
    line-height: 20px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 56, 255, 0.7);
  }

  30% {
    box-shadow: 0 0 0 10px rgba(0, 56, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 56, 255, 0);
  }
}

@keyframes pulsePrevious {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  30% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
