@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

.exhibitionPost-Page {
  padding: 100px 0;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  overflow: auto;
  transition: opacity 0.3s ease-in;

  &-wrapper {
    position: relative;
  }
  &__header {
    display: grid;
    grid-template-columns: 450px 762px;
    gap: 12px;
    padding-bottom: 85px;
    margin-left: -132px;
  }
  &__body {
    display: grid;
    grid-template-columns: 450px 762px;
    gap: 12px;
    margin-left: -132px;
  }
  &__title {
    @include baad-text(60px, 73.38px, 0, 300, normal, 'Akkurat Light');
    text-transform: uppercase;
    display: flex;
    align-items: start;
    //margin-left: 80px;
  }
  &__subtitle {
    font-family: 'Akkurat Beta Regular';
    font-size: 22px;
    margin-left: 20px;
    line-height: 44px;
  }
  &__text {
    @include baad-text(22px, 27px);
  }
  &__list {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 108px 300px;
    gap: 47px;
    p {
      //@include baad-text(13px, 16px, 1.5px, normal, normal, 'Akkurat Mono');
      font-family: "Akkurat Beta Regular";
      font-size: 16px;
      text-transform: uppercase;
      color: #FF0000;
    }
    ul {
      padding: 0;
      li {
        text-transform: uppercase;
      }
    }
    &-item {
      //@include baad-text(13.5px, 16px, 0, normal, normal, 'Akkurat Mono');
      font-family: "Akkurat Beta Regular";
      list-style: none;
      font-size: 16px;
      line-height: 23px;
      color: #000000;
      &--light {
        color: #9D9D9D;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1280px;
    }
  }
}

.close-icon {
  z-index: 102;
  position: fixed;
  right: 20px;
}
.close-icon {
  top: 20px;
}

.exhibitionPost-Page__logo {
  display: none;
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .exhibitionPost-Page {
    background-color: rgba(255, 255, 255, 0.95);
  }
}

@media screen and (max-width: 480px) {
  .exhibitionPost-Page {
    &__lists {
      max-width: 320px !important;
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .exhibitionPost-Page {
    padding-top: 40px;
    padding-bottom: 160px;
    .container {
      padding: 0 30px;
    }
    &__header {
      padding-bottom: 20px;
      grid-template-columns: 1fr;
    }
    &__body {
      grid-template-columns: 1fr;
    }
    &__title {
      font-size: 30px;
      line-height: 36.69px;
      display: inline-block;
    }
    &__subtitle {
      font-size: 10px;
      top: -14px;
      position: relative;
      line-height: 12.51px;
      margin-left: 5px;
    }
    &__lists {
      order: 1;
    }
    &__list {
      margin-bottom: 20px;
      grid-template-columns: 1fr 150px;
      p, &-item {
        font-size: 10px;
      }
    }
    &__text {
      font-size: 12px;
      line-height: 16px;
    }
    &__logo {
      display: block;
      max-width: 90px;
    }
    &__btn-wrapper {
      pointer-events: none;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 102;
      height: 287px;
      background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #F0F0F0 100%);
      display: flex;
      justify-content: center;
      align-items: end;
      -webkit-align-items: flex-end;
      padding-bottom: 23px;
      button {
        font-family: "Akkurat Beta Regular";
        pointer-events: auto;
        padding: 20px 30px;
        min-width: 201px;
      }
    }
  }
  .close-icon {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .exhibitionPost-Page {
    &__header {
      margin-left: 0;
    }
    &__body {
      margin-left: 0;
    }
  }
}
