.btn-outlined {
  font-family: "Akkurat Mono";
  background: #F0F0F0;
  border: 1px dashed #9D9D9D;
  color: #9D9D9D;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 10px 20px;
  min-width: 143px;
  text-decoration: none;
  display: block;
  &:hover {
    color: #9D9D9D;
    text-decoration: none;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
  }
  &--dark {
    background: #000000;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #F0F0F0;
      border: 1px dashed #F0F0F0;
    }
  }
}
