/*---- Up enter and exit -----*/
.enter-Up {
    &.page-enter {
      animation: slideInUp 1s forwards;
    }
  }

  .exit-Up {
    &.page-exit {
      animation: slideOutUp 1s forwards;
    }
  }

  /*---- Down enter and exit -----*/

  .enter-Down {
    &.page-enter {
      animation: slideInDown 1s forwards;
    }
  }

  .exit-Down {
    &.page-exit {
      animation: slideOutDown 1s forwards;
    }
  }

  /*---- Right enter and exit -----*/
  .enter-Right {
    &.page-enter {
      animation: slideInRight 1s forwards;
      animation-timing-function: "cubic-bezier(.23,0,0.4,0)";
    }
  }

  .exit-Right {
    &.page-exit {
      animation: slideOutRight 1s forwards;
    }
  }

  /*---- Left enter and exit -----*/
  .enter-Left {
    &.page-enter {
      animation: slideInLeft 1s forwards;
      animation-timing-function: "cubic-bezier(.23,0,0.4,0)";
    }
  }

  .exit-Left {
    &.page-exit {
      animation: slideOutLeft 1s forwards;
    }
  }

  /*---- page transition key frames ---- */
  @keyframes slideInLeft {
    from {
      transform: translate(-100%, 0);
      visibility: visible;
    }

    to {
      transform: translate(0, 0);
    }
  }

  @keyframes slideOutLeft {
    from {
      transform: translate(0, 0);
    }

    to {
      visibility: hidden;
      transform: translate(-100%, 0);
    }
  }

  @keyframes slideInRight {
    from {
      transform: translate(100%, 0);
      visibility: visible;
    }

    to {
      transform: translate(0, 0);
    }
  }

  @keyframes slideOutRight {
    from {
      transform: translate(0, 0);
    }

    to {
      visibility: hidden;
      transform: translate(100%, 0);
    }
  }

  @keyframes slideInUp {
    from {
      transform: translateY(100%);
      visibility: visible;
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes slideOutUp {
    from {
      transform: translate(0, 0);
    }

    to {
      visibility: hidden;
      transform: translate(0, -100%);
    }
  }

  @keyframes slideInDown {
    from {
      transform: translate(0, -100%);
      visibility: visible;
    }

    to {
      transform: translate(0, 0);
    }
  }

  @keyframes slideOutDown {
    from {
      transform: translateY(0);
    }

    to {
      visibility: hidden;
      transform: translateY(calc(100% - 60px));
    }
  }

  /* page transition key frames end*/
