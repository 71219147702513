@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

.video-template{
    div.randomTip {
        display: flex;
        text-decoration: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        &:hover{
            position: relative;
        }

        .media-style {
            object-fit: cover;
            overflow: hidden;
            width: 100%;
        }
        .page-descrition {
            display: none;
            position: fixed;
            padding: 3px 3px 5px 5px;
            top: 60px;
            right: 12px;
            z-index: 2;
            background-color: white;
            right: 10px;
            width: 32%;
            animation-delay: 10s;
            font-family: sans-serif;
        }
    }
    .panel-footer {
        top: 0;
    }
}

div.video-template ~ div#globalCursor {
    position: inherit;
}

#sound-box {
    display: none;
}
.toggle-mute {
    position: absolute;
    z-index: 222;
    bottom: 0;
    width: 74.38px;
    height: 78.09px;
    background-position: 50%;
    -webkit-background-size: cover;
    background-size: cover;
    left: 100px;
}

.toggle-mute {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA4MCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgMjUuMzEyNVY1OC43ODEySDIxLjU5MzhMNTEuMzQzOCA4MS4wOTM4VjNMMjEuNTkzOCAyNS4zMTI1SDNaIiBzdHJva2U9IiNDOUNCREEiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik02OS45Mzc1IDI5LjAzMTJDNjkuOTM3NSAyOS4wMzEyIDc3LjM3NSAzMi43NSA3Ny4zNzUgNDIuMDQ2OUM3Ny4zNzUgNTEuMzQzOCA2OS45Mzc1IDU1LjA2MjUgNjkuOTM3NSA1NS4wNjI1IiBzdHJva2U9IiNDOUNCREEiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    top: 30px;
    left: 40px;
    &.toggle-mute--muted {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA5MCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjYyLjM5MDQiIHkxPSI1NS43NTM3IiB4Mj0iODguMjE2NSIgeTI9IjI5LjkyNzYiIHN0cm9rZT0iI0M5Q0JEQSIgc3Ryb2tlLXdpZHRoPSI1Ii8+CjxsaW5lIHgxPSI4Ny4wNDI4IiB5MT0iNTQuNTkzOSIgeDI9IjYxLjIxNjciIHkyPSIyOC43Njc5IiBzdHJva2U9IiNDOUNCREEiIHN0cm9rZS13aWR0aD0iNSIvPgo8cGF0aCBkPSJNMyAyNS4zMTI1VjU4Ljc4MTJIMjEuNTkzOEw1MS4zNDM4IDgxLjA5MzhWM0wyMS41OTM4IDI1LjMxMjVIM1oiIHN0cm9rZT0iI0M5Q0JEQSIgc3Ryb2tlLXdpZHRoPSI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        width: 4.53vw;
        height: 4.067vw;
    }
}

//TEXT/FONTS:
.video-template {
    h1.H1StyleforRandomPage {
        @include baad-text(21px, 21px, 2px, bold);
        margin: 0;
    }
    h3.H3StyleforRandomPage {
        @include baad-text(18px, 21px);
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 667px) {
    .toggle-mute {
        width: 31px;
        height: 32.55px;
        top: 14px;
        left: 20px;
        &.toggle-mute--muted {
            width: 36.52px;
            height: 32.79px;
        }
    }
}
