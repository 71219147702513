body {
    margin: 0;
    //font-family: "LL Akkuratmono Pro Regular Web";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    border-color: $baad-main-color;
    color: $baad-main-color;
    background: $baad-main-color;
    background-color: $baad-main-color;
}

a {
    //cursor: pointer;
    cursor: none;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.page {
    overflow: hidden;
    position: fixed;
    height: 100vh;
    top: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    background: #f0f0f0;
    // transition: transform 3s ease-in-out !important;
}

.width-fit-content {
    width: fit-content;
}

.contentHidden {
    visibility: hidden;
}

.show-item {
    display: block !important;
    
    &.tooltip--hidden {
        display: none !important;
    }
}

.hide-item {
    display: none !important;
}

.hide-item-opacity {
    opacity: 0;
    width: 0 !important;
}

.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.opacity-0 {
    opacity: 0;
    pointer-events: none;
}

.opacity-1 {
    opacity: 1;
}

@media screen and (min-width: 500px) {
    .layout-lock {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
}
