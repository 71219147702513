@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote( ( $y / $x ) * 100 + '%' );
	position: relative;	

	@if $pseudo {
		&::before {
			@include pseudo($pos: relative);
			//width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}

	img, iframe {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	img {
		object-fit: contain;
	}
}

.cursor-guide-video {
    margin-left: 20px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    border-radius: 7px;
    width: 240px;
    overflow: hidden;
    @include responsive-ratio(720, 480, true);
    margin-bottom: 20px;
    position: relative;

    .cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;        
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(10px);
        z-index: 1;
    }

    .live-status {
        position: absolute;
        width: 42px;
        height: 21px;
        text-transform: uppercase;
        top: 9px;
        right: 7px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Akkurat;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
    }

    .numberOfUsers {
        position: absolute;
        text-transform: uppercase;
        bottom: 9px;
        right: 18px;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: Akkurat;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;

        &::before {
            display: block;
            width: 13px;
            height: 9px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../../../assets/user-icon.svg);
            content: '';
        }

        .user-icon-path {
            color: blue;
            fill: blue;
        }
    }

    #cursor-video {
        width: 240px;
        border: none;
    }
}

.cursor-local {
    position: absolute;

    .cursor-guide-video {
        position: fixed;
        bottom: 0;
        right: 20px;
    }
}