@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

.humanActivity-page {
  // &.whatever-class {
  //   cursor: url("/fiveone.png") 12 0, auto;
  // }

  cursor: none;

  .humanActivitiesMainDiv {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    justify-content: space-evenly;
    cursor: none;
  }
  .justify-content-evenly{justify-content: space-evenly !important;}

  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FFF;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.6s ease-in-out, filter 0.8s ease-in-out;
    &--active {
      opacity: 1;
    }
    &--blur {
      filter: blur(30px);
    }
  }
  .vertical-whiteLine {
    position: relative;
    width: 40px;
    cursor: none;

    &::after {
      position: absolute;
      content: '';
      border-right: 1px dashed;
      margin-left: 20px;
      height: 100%;
      z-index: 1;
    }

    &:hover {

      &::after {
        border-right: 2px solid;
      }
    }
    &.visited::after {
      border-color: #0038FF;
    }
  }

  .tooltip-content {
    display: none;
    background: #F0F0F0;
    color: #000000;
    //width: 198px;
    position: absolute;
    margin-top: -1.771vw;
    border: 0.75px #000 solid;
    padding: 2.083vw 1.563vw 2.344vw;
    width: 16.017vw;
    text-transform: uppercase;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    z-index: 3;

    &.tooltip--hidden {
      visibility: none;
    }
  }
  .secondImagesSlide {
    border-left: 0 !important;
  }
}

//TEXT/FONTS:
.humanActivity-page {
  .tooltip-content {
    font-family: "Akkurat Mono";
    font-size: 0.833vw;
    line-height: 1em;
    //width: auto;
    height: auto;

    > span {
      display: block;
      margin-bottom: 15px;

      &:last-child {
        margin: 0;
      }
    }
    // &--big {
    //   height: 342px;
    //   width: 309px;
    // }
  }
}

.lineCursor {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #0038FF;
  pointer-events: none;
  &--mobile {
    display: none;
  }
}

.image-animation--fade-in {
  -webkit-animation: in 0.3s 0s;
  animation: in 0.3s 0s;
}
.image-animation--fade-out {
  -webkit-animation: out 0.3s 0s;
  animation: out 0.3s 0s;
}

@-webkit-keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.humanActivity-page {
  .panel-footer {
    display: none;
  }
  &.page-enter-active + .footer {
    .footer__title,
    .footer__subtitle {
      opacity: 0;
    }
  }
  &.page-enter-done + .footer {
    .footer__title,
    .footer__subtitle {
      opacity: 1;
      transition: all 1.8s ease;
    }
  }
}
.tooltip__img {
  width: 12.813vw;
  height: 9.479vw;
  margin-bottom: 1.146vw;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 500px) {
  .lineCursor {
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
  }
  .humanActivity-page {
    .vertical-whiteLine {
      min-width: 100px;
      scroll-snap-align: end;
      scroll-snap-stop: always;
    }
    .humanActivitiesMainDiv {
      overflow-x: scroll;
      justify-content: unset;
      scroll-snap-type: x mandatory;
      transform: translateX(-5px);
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__image {
      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(10px);
      }
    }
    .mobile-navigation {
      opacity: 0;
      &--active {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }
    }
    .tooltip-content {
      display: block;
      margin: 0;
      top: 23px;
      left: 17px;
      min-width: 223px;
      width: 223px;
      height: 83px;
      padding: 15px 30px 20px 20px;
      font-size: 12px;
      line-height: 13px;
      z-index: 1;
    }
    .arrow-top {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 120px;
      background: #F0F0F0;
      backdrop-filter: blur(30px);
      border-radius: 100%;
      width: 42px;
      height: 42px;
      border: 1px solid #000000;
      display: grid;
      place-content: center;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      margin: auto;
      z-index: 1;
      transition: opacity 0.3s ease-in-out;
      &--hidden {
        opacity: 0;
      }
    }
  }
}

.biennale-logo {
  position: absolute;
  top: 42px;
  right: 15px;
  width: 100%;
  height: auto;
  max-width: 88px;
  @media screen and (min-width: 500px) {
    top: 2.5vw;
    right: 1vw;
    max-width: 9vw;
  }
}

