@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

.panel-footer {
  position: absolute;
  height: $baad-bottom-padding;
  bottom: 0;
  //left: 3%;
  //width: 400px;
  width: 100%;
  border: none;
  z-index: 100;
  left: 0;
  padding: 0 1.875vw;
  //margin-left: calc(((100% - 2*#{$baad-main-padding})*0.0833) + #{$baad-main-padding});
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F0F0F0;
  border-bottom: 1px solid #000000;

  .panel-footer__title-wrapper {
    display: flex;
    align-items: center;
  }

  .panel-footer__title {
    display: flex;
    align-items: start;
  }

  .row .col-sm-4,
  .row .col-sm-8 {
    padding: 0;
    //display: flex;
    display: contents;
    align-items: center;
    width: auto;
  }

  .row .col-sm-4 {
    width: 198px;
  }

  .headingForRedirect{
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0;
    display: flex;
    align-items: center;
    span {
      font-size: 30px;
    }
  }
}

// this is for global footer component
.footer {
  position: fixed;
  bottom: 0;
  //height: 80px;
  height: 97px;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  //align-items: baseline;
  align-items: center;
  line-height: 1;
  //padding-left: 6%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: $baad-footer-header-padding 1.875vw;
  background: #F0F0F0;
  border-top: 1px solid #000000;

  .footer__title-wrapper {
    display: flex;
    align-items: center;
  }

  .footer__title {
    @include baad-text(54px, 64px, 0, 300, normal, "Akkurat Light");
    // width: 100%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    -webkit-font-smoothing: antialiased;
    text-transform: uppercase;
    //cursor: pointer;
    
    &--animated {
      text-transform: none;
    }
    transition: color 0.3s ease-in-out;
    &:hover {
      color: #0038FF;
    }
  }
  .footer__subtitle {
    font-family: "Akkurat Mono";
    min-width: 183px;
    margin-left: 20px;
    text-transform: uppercase;
  }
   .thewrongbiennale-logo {
    max-width: 120px;
     margin-right: 30px;
  }
  .galerierudigerschottle-logo {
    width: 306px;
    height: 32px;
  }
  .string-logo {
    text-decoration: none;
    span {
      font-family: 'Akkurat Beta Regular';
      font-size: 14px;
      color: #000000;
      margin-right: 10px;
    }
    img {
      width: 40px;
      height: 34px;
    }
  }
  .galerierudigerschottle-logo,
  .string-logo {
    opacity: 0.3;
    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }
}
.footer_subtitle{
  cursor:inherit;
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 21px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  color: $baad-main-color;
  text-decoration: none;
  position: relative;
  bottom: 0px;
  left: -10px;
  &:hover{
    text-decoration: none;
    color: $baad-main-color;
  }
}
.footer_main_link{
  color: $baad-main-color;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: $baad-main-color;
  }
}
.footer-d-n{
  display: none !important;
}

.randomTeaser-page ~ #footer_content{
  display: none;
}

span.footer__subtitle.animsition-link {
  height: 0;
  //display: block;
  display: none;
}


//TEXT/FONTS:
.panel-footer {
  h2.headingForRedirect {
    @include baad-text(21px, 17px, 0, normal, normal, "Akkurat Mono");
    //margin: 16px 0 0 12px;
    //margin: 20px 0 0 8px;
    margin-left: 20px;
    //padding-top: 13px;
  }
  .headingForRedirect{
    font-family: "Akkurat Light";
    margin: 0;
  }
  h1.headingForRedirect {
    padding: 1px 0;
  }
  h1.headingForRedirect span {
    @include baad-text(42px, 42px, 4px);
    margin: 0
  }
  h1.headingForRedirect span sup {
    @include baad-text(42px, 42px, 4px);
    top: 0;
    font-size: 42px !important;
    margin: 0
  }
  h2.headingForRedirect {
    font-size: 16px;
  }
}
#footer_content {
  h1 {
    font-family: "Akkurat Light";
    margin-bottom: 0;
  }
  .footer__title.short .whole-word {
    max-width: 30px;
  }

  a.footer_subtitle {
    @include baad-text(18px, 18px, 4px);
    margin: 0 0 0 21px;
    bottom: 1px;
  }
}

.footer__dot {
  //cursor: pointer;
  height: 26px;
  min-width: 26px;
  background-color: #0038FF;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2.7vw;
  box-shadow: 0 0 0 0 rgba(0, 56, 255, 1);
  transform: scale(1);
  animation: pulse-dot 2.1s infinite;

  &.inactive {
    animation: none;
  }
}

@keyframes pulse-dot {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 255, 0);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 17px rgba(0, 0, 255, 0);
	}
	
	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 255, 0.6);
	}
}

.panelFooter-icon {
  font-family: "Akkurat Light";
  border: 0;
  color: #000000;
  font-size: 3.125vw;
  background: none;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  &--left {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    margin-left: 10px;
    margin-right: 25px;
  }

  &:hover{
    text-decoration: none;
  }
}

.btn--dots {
  display: none;
}

.panelFooter__arrow {
  display: none;
}

@media screen and (max-width: 480px) {
  .panel-footer {
    box-shadow: 0 1px 17px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    border-bottom: 1px solid #000000;
    .headingForRedirect {
      font-weight: 400;
      font-size: 12px;
      letter-spacing: unset;
      line-height: unset;
    }
    h2.headingForRedirect {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }
    .panel-footer__title {
      flex-direction: column;
    }
  }
  .btn--dots {
    font-family: Akkurat;
    font-weight: 500;
    font-size: 30px;
    display: block;
    padding: 0;
    border: 0;
    background: none;
    margin-top: -15px;
  }
  .panelFooter__arrow {
    display: block;
    font-family: Akkurat;
    position: absolute;
    top: 37px;
    right: 16px;
    font-weight: 500;
    font-size: 22px;
    line-height: 19px;
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 1024px) {
  .panel-footer {
    height: 97px;
    padding: 0 32px;
    .headingForRedirect {
      font-size: 12px;
      font-family: "Akkurat Beta Regular";
    }
    h2.headingForRedirect {
      font-size: 12px;
      font-family: "Akkurat Beta Regular";
    }
  }
  .footer__dot {
    height: 26px;
    width: 26px;
    margin-right: 20px;
  }
  .footer {
    padding: $baad-footer-header-padding 32px;
    .footer__title {
      font-size: 12px;
      line-height: 20px;
    }
    .footer__subtitle {
      margin: 0;
      font-size: 12px;
      line-height: normal;
    }
  }
  .panel-footer__title {
    margin-top: -2px;
  }
  #footer_content h1{
    font-family: "Akkurat Mono";
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    height: 5.938vw;
  }
  .panel-footer {
    .headingForRedirect {
      font-size: 3.125vw;
      line-height: 2.813vw;
    }
    h2.headingForRedirect {
      font-size: 1.146vw;
      line-height: 1.563vw;
      margin-bottom: 0;
    }
  }
  .footer {
    .footer__title {
      font-size: 3.125vw;
      line-height: 2.813vw;
    }
    h2.footer__subtitle {
      font-size: 1.146vw;
      line-height: 1.563vw;
      margin-bottom: 0;
    }
  }
}
