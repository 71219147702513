@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

.artist-page {
  /* image panel part */
  .image-panel {
    position: relative;
    padding: 0;
    .panel-image {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: auto;
      //z-index: 1;
      //width: auto;
      //max-width: calc(100vw - 40px);
      z-index: 2;
      //max-width: 115.1666%;
      //height: 100%;

    }
    .icon-image {
      height: 100%;
      display: none;
      z-index: 999;
      width: calc(100vh - 88px);
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      .cross-div {
        background-color: #ff00ff;
        position: absolute;
        &.h-cross {
          height: 100%;
          width: 152px;
          left: 50%;
          transform: translateX(-50%);
        }
        &.w-cross {
          height: 152px;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.cross-kill {
        transform: translateX(-50%) rotate(45deg);
        height: calc(100% + 50px);
        width: calc(100vh - 38px);
      }
    }
    .prompted-image{
      z-index: 4;
    }
  }
  /* end */

  .h1HeadingStyle {
    margin-top: 10px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 20px;
  }
  .panels-item{
    overflow-y:  auto;
  }
  .show-for-a-while {
    opacity: 1;
  }

  #panels-item--one:hover .show-for-a-while {
    display: block;
    -moz-animation: cssAnimation 0s ease-in 1s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 1s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 1s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 1s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  #panels-item--two {
    overflow-x: hidden;
  }

  .panels-item--two {
    padding-top: 10px;
    .h1HeadingStyle {
      margin-top: 70px;
      text-decoration: underline;
    }
  }

  .sub-title {
    //font-size: 28px;
    margin: 0;
    //font-weight: 400;
  }

  .panel-footer {
    top: 0;
  }
  .panel-footer h2.headingForRedirect {
    //margin: 16px 0 0 0px;
    margin: 20px 0 0 8px;
  }
  .column_div h1{
    //font-size: 42px;
    margin: .67em 0;
  }
  .column_div p {
    //font-size: 18px;
    //line-height: 1.3;
    margin-bottom: 12px;
  }

  .content-column1 {
    padding: $baad-main-padding;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
  }

  .row {
    flex-wrap: initial;
  }
  .image_title_wrap {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 9;
    width: 100%;
    p {
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0;
      font-weight: normal;
      font-style: normal;
      font-family: Akkurat Beta Regular;
      color: #000000;
      display: inline-block;
      background: #ffffff;
      padding: 0px 5px;
      margin: 0;
    }
  }
  
  //// From: https://codepen.io/giana/pen/dWJzMK/
  //.hover-cursor2 h2, .hover-cursor2 p {
  //  // Everything before the hovered element
  //  // Since we can't select previous elements, we simply set it as the default and then overwrite subsequent elements
  //  //background: #fad29c;
  //  color: #8c8c8c;
  //  &::before {
  //    content: '';
  //  }
  //  // The hovered element
  //  &:focus,
  //  &:hover {
  //    //background: #fff;
  //    color: $baad-main-color !important;
  //    &::before {
  //      content: '';
  //    }
  //  }
  //  // Everything after the hovered element
  //  // ~ is the sibling selector https://developer.mozilla.org/en/docs/Web/CSS/General_sibling_selectors
  //  &:focus ~ &,
  //  &:hover ~ & {
  //    //background: #6ef1cd;
  //    color: #8c8c8c;
  //    &::before {
  //      content: '';
  //    }
  //  }
  //}
  //// If the container isn't hovered, set some default styles
  //// No IE/Edge support for :focus-within but you can restructure/remove this
  //// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within
  //#panels-item--three:not(:focus-within):not(:hover) {
  //  .hover-cursor2 h2, .hover-cursor2 p {
  //    //background: #f7ffb5;
  //    color:$baad-main-color;
  //    &::before {
  //      content: '';
  //    }
  //  }
  //}
  //

}

@keyframes cssAnimation {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    opacity: 0;
  }
}



//TEXT/FONTS:
.artist-page {
  .content-column1 {
    h1 {
      @include baad-text(42px, 42px, 0);
      margin: 0;
    }
    p {
      @include baad-text();
      margin: 0;
      text-indent: 40px;
    }
    .footnote {
      @include baad-text(11px,11px);
      position: relative;
      top: -6px;
      padding: 4px;
      margin: -4px;
    }
  }
  .panels-item--two {
    h2 {
      @include baad-text(42px, 42px, 0);
      font-variant-numeric: slashed-zero;
    }
    p {
      @include baad-text();
      margin: 0;
    }
    p::first-line {
      @include baad-text(18px, 24px);
    }
    h1.h1HeadingStyle {
      @include baad-text(42px, 42px, 0);
      margin: 21px 0 0 0;
      text-decoration: none;
    }
  }
  .panels-item--three {
    z-index:6;
    .hovering-div {
      display: flex;
      flex-direction: column-reverse;
    }
    h1.h1HeadingStyle {
      //@include baad-text(42px, 42px, 0, normal, normal, Akkurat Beta Regular);
      @include baad-text(42px, 42px, 0, normal, normal, Akkurat Mono);
      margin-top: 0;
      text-transform: uppercase;
      padding-top: 2px;
    }
    h2.sub-title {
      //@include baad-text(42px, 42px);
      font-size: 42px;
      line-height: 42px;
      letter-spacing: 0;
      font-weight: normal;
      //font-style: normal;
      font-family: Akkurat Beta Regular;
      color: $baad-main-color;
    }
    h1.h1HeadingStyle ~ div.width-fit-content:nth-of-type(1) h2 {
      @include baad-text(42px, 42px);
    }
    p.panelPStyle {
      //@include baad-text();
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0;
      font-weight: normal;
      //font-style: normal;
      font-family: Akkurat Beta Regular;
      color: $baad-main-color;
      margin: 0;
    }
    p.panelPStyle::first-line {
      //@include baad-text(18px, 24px);
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0;
      font-weight: normal;
      //font-style: normal;
      font-family: Akkurat Beta Regular;
      color: $baad-main-color;
    }
  }
}

//Changes with right column
.artist-page #panels-item--three {
  .hover-cursor2 {
    opacity: 1.0;
    font-size: 20px;
    //font-weight: bold;
  }
  .hover-cursor2:hover {
    opacity: 0.55;
    //opacity: 1.0;
  }
  .hovering-div:hover > .hover-cursor2 {
    opacity: 0.55;
    //opacity: 1.0;
  }
  .hovering-div:hover > .hover-cursor2:hover {
    opacity: 1.0;
    font-style: italic;
  }
}
