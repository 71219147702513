@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

/* css for home start */
.home-page {
  .left-main-div {
    margin-left: 8.33%;
    padding: 12px;
    transition: all 500ms;
    .category-part {
      display: flex;
      flex-wrap: wrap;
    }
    .each-category-item {
      padding-right: 12px;
      p {
        margin: 0;
        span {
          padding-right: 6px;
        }
      }
    }

    .alertStyle {
      position: fixed;
      right: 0;
      left: auto;
      width: 33.33%;
      max-width: 33.33%;
      padding: 5px;
      color: $baad-main-color !important;
      opacity: 0;
      z-index: 15;
      background-color: white;
      // margin-right: calc(8.3333%);
      margin-right: 12px;
      padding: 0 2px;

      &.change-opacity {
        opacity: 0;
        will-change: opacity;
        -webkit-transition-duration: 2000ms;
        -o-transition-duration: 2000ms;
        transition-duration: 2000ms;
        -webkit-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
      }
    }
  }

  .sliderStyle {
    border-left: 1px solid;
    padding: 0 !important;
    max-height: 100%;
    overflow: hidden;

    .imgStyle {
      height: calc(100vh - #{$baad-bottom-padding} - #{$baad-main-padding});
      width: calc(100vw - 2*#{$baad-main-padding});
      object-fit: cover;
      object-position: top left;
    }
  }

  .secondLinkDiv {
    margin-left: -75%;
  }

  .FistImagesSlide {
    min-width: 25%;
    border-left: $baad-ha-border;
  }

  .secondImagesSlide {
    min-width: 100%;
    border-left: 1px solid;
  }

  #imagesSlide {
    transition: all 500ms;
  }

  .react-slideshow-container .nav {
    display: none !important;
  }

  .animation-content {
    display: flex;
    overflow: hidden;
    height: 100%;

    .slide-home {
      width: 100%;
      display: flex;
      flex: 0 0 auto;
      transition: all 2s;
      .main-part {
        width: 75%;
      }

      .human-part {
        width: 25%;
      }
    }

    .lines {
      //width: calc(100vw - 24px);
      width: calc(100vw - 2*#{$baad-main-padding});
      flex: 0 0 100%;

      .artistBarsStyle {
        border-style: solid;
        display: flex;
        justify-content: space-evenly;
        height: 100%;
        border-color: $baad-main-color;

        .vertical-line {
          border-style: solid;
          border-color: $baad-main-color;
          border-top: none;
          border-left: none;
          border-bottom: none;
          height: 100%;
        }
      }
    }
  }

  /* css for home end ------------------ */

  /* css for human Activities start -----------------------------*/

  .humanActivity-page__image {
    background-repeat: no-repeat;
    background-size: cover;
    //width: calc(100vw - 24px);
    width: 100vw;
    height: 100%;
    //background-position: top left;
    background-position: 50% 33%;
    z-index: -1;
  }
}
#mainHome .FistImagesSlide {
  min-width: 25%;
  border-left: $baad-main-border;
}

/* css for human Activities---------------------------- end */
//TEXT/FONTS:
.home-page {
  .left-main-div .each-category-item {
    @include baad-text(18px, 25px, -1px, normal, normal, Akkurat Mono, $baad-main-color);
  }
  .item-number {
    @include baad-text(20px, 20px, -1px, bold, normal, Akkurat Mono, $baad-main-color);
  }
  .category-desctiption__name {
    @include baad-text(18px, 21px, 4px);
    text-transform: uppercase;
  }
  .category-desctiption__text {
    @include baad-text(18px, 21px, 0, normal, normal, Times New Roman);

  }
}

