.whatever-class {
    @media (min-width: 768px) {
        /*96 means 9.6 bootstrap grid columns*/
        .col-md-96 {
            -ms-flex: 0 0 80%;
            flex: 0 0 80%;
            max-width: 80%;
        }

        /*24 means 2.4 bootstrap grid columns*/
        .col-md-24 {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (min-width: 992px) {
        /*96 means 9.6 bootstrap grid columns*/
        .col-lg-96 {
            -ms-flex: 0 0 80%;
            flex: 0 0 80%;
            max-width: 80%;
        }

        /*24 means 2.4 bootstrap grid columns*/
        .col-lg-24 {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

// panel css start----------
.panels-item {
    top: 0;
    z-index: 1;
    background: #f0f0f0;
    height: 100%;
}

.panels-item--two {
    left: 0;
    border-right: $baad-ha-border;
}

.panels-item--three {
    right: 0;
    border-left: $baad-ha-border;
}

.action-panel {
 z-index: 7 !important;
}
// end panel css-----------

.row {
    margin: 0 !important;
    height: 100%;
    overflow-x: hidden;
}

[class^="col"] {
    height: auto;
}

.headerDiv {
    padding-bottom: 5.938vw;
    height: 100vh;
    overflow: hidden;
    line-height: 1.2;
    transition: padding 0.5s;
}

.headerDiv-artist {
    padding: $baad-bottom-padding 0 0;
}

@media screen and (max-width: 600px) {
    .headerDiv {
        padding: 0;
        height: calc(100vh - 97px);
    }
    .row {
        overflow-x: visible;
    }
}

@media screen and (max-width: 992px) {
    .headerDiv-artist {
        padding: 97px 0 0;
    }
}

@media screen and (min-width: 1920px) {
    .headerDiv {
        padding-bottom: 5.938vw;
    }
    .headerDiv-artist {
        padding: 5.938vw 0 0;
    }
}
