@import "../../assets/styles/_mixins.scss";

.about-page {
  background: #F0F0F0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(100%);
  transition: transform 2s, visibility 2s;
  z-index: 1;
  cursor: none;

  &--active {
    visibility: visible;
    pointer-events: unset;
    transform: translateY(0);
  }
}

.about-page__header {
  background: #F0F0F0;
  box-shadow: 0 1px 17px rgba(0, 0, 0, 0.2);
  padding: 0 32px;
  border-bottom: 1px solid #000000;
  display: flex;
  align-items: center;
  position: relative;
  height: 97px;
}

.about-page__arrow {
  font-family: "Akkurat";
  font-weight: 500;
  font-size: 22px;
  line-height: 19px;
  transform: rotate(270deg);
  position: absolute;
  top: 37px;
  right: 32px;
  color: #000000;
}

.about-page__title {
  font-family: "Akkurat Mono";
  text-transform: uppercase;
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  span {
    display: block;
    margin: 0;
    font-size: 12px;
    line-height: normal;
  }
}

.about-page__wrapper {
  padding: 32px 32px 250px;
  height: calc(100% - 5.938vw);
  overflow: auto;
}

.about-page__col {
  display: none;
  &--active {
    display: block;
  }
}

.about-page__text {
  font-family: "Akkurat Light";
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin: 0;
}

.about-page__text__paragraph {
  display: block;
}

.about-page__credit {
  font-family: "Akkurat Mono";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0038FF;
  text-align: right;
  margin-top: 20px;
  display: block;
}

.about-page__text:not(:first-child) {
  text-indent: 15px;
}

.about-page__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.about-page__item {
  font-family: "Akkurat Mono";
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  &--primary {
    color: #0038FF;
  }
  &--light {
    color: #9D9D9D;
    span:nth-child(even) {
      display: block;
      margin-bottom: 20px;
    }
  }
  &--logos {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }
}

.about-page__logo {
  width: 100%;
  height: auto;
  &--israel {
    max-width: 38px;
  }
  &--artist {
    max-width: 38px;
  }
  &--outset {
    max-width: 38px;
  }
  &--mifal {
    max-width: 30px;
  }
}

@media screen and (min-width: 768px) {
  .about-page__title {
    @include baad-text(54px, 64px, 0, 300, normal, "Akkurat Light");
    display: flex;
    align-items: flex-start;
    span {
      font-family: "Akkurat Mono";
      font-size: 2rem;
      margin-left: 20px;
    }
  }
}

@media screen and (min-width: 992px) {
  .about-page__bg {
    background: rgba(0, 0, 0, 0);
    position: fixed;
    width: 100vw;
    height: 100%;
    bottom: 0;
    cursor: pointer;
    transform: translateY(100%);
    &--active {
      transform: translateY(0);
    }
  }
  .about-page {
    height: calc(100% - 5.938vw);
    transform: translateY(calc(100% - 5.938vw));
    &--active {
      transform: translateY(0);
    }
  }
  .about-page__header {
    padding: 0 1.875vw;
    height: 5.938vw;
    box-shadow: none;
    border-top: 1px solid #000000;
  }
  .about-page__arrow {
    font-family: "Akkurat Light";
    //cursor: pointer;
    cursor: none;
    font-weight: 300;
    font-size: 3.125vw;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  .about-page__title {
    font-family: "Akkurat Light";
    font-weight: 300;
    font-size: 3.125vw;
    line-height: 2.813vw;
    span {
      font-family: "Akkurat Mono";
      font-weight: 400;
      font-size: 1.146vw;
      line-height: 1.563vw;
      margin-left: 15px;
    }
  }
  .about-page__wrapper {
    display: grid;
    grid-template-columns: 1fr 33%;
    padding: 0;
    overflow: hidden;
  }
  .about-page__wrapper .about-page__col:first-child {
    display: grid;
    gap: 46px;
    grid-template-columns: 1fr 1fr;
  }
  .about-page__wrapper .about-page__col:last-child {
    border-left: 1px solid #000000;
  }
  .about-page__col {
    padding: 1.25vw 1.875vw;
    overflow: auto;
    display: block;
  }
  .about-page__text {
    font-family: Akkurat;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
  }
  .about-page__credit {
    font-size: 16.5px;
  }
  .about-page__list {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 50px;
  }
  .about-page__item {
    margin-bottom: 45px;
    max-width: 235px;
    &--light {
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 1300px) {
  .about-page__item {
    font-size: 16px;
    line-height: 23px;
  }
  .about-page__text {
    font-size: 16.5px;
    line-height: 22.5px;
  }
}

@media screen and (min-width: 1400px) {
  .about-page {
    height: 85%;
    transform: 85%;
    &--active {
      transform: translateY(0);
    }
  }
}
