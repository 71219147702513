.cursor-chat__wrapper {
    pointer-events: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 99999999;
}

.cursor-icon {
    width: 18px;
    height: 18px;
    border-radius: 18px;
}

.online-cursor-wrapper__cursor {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.online-cursor-wrapper__input-box {
    border-radius: 8px;
    padding: 4px 8px;
    margin-top: 0px;
    margin-left: 10px;

    input {
        background: none;
        color: black;
        border: none;
    }
}

.online-cursor-wrapper__text {
    border-radius: 8px;
    padding: 4px 8px;
    margin-top: 0px;
    margin-left: 10px;
}

.user-list-string {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 13px;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .user-icon {
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        margin-bottom: 8px;
        border: 1px dashed;
        border-color: #0038FF;
    }
}

.message_indicator {

}