@import "_variables.scss";
//Mixins:
@mixin baad-text($font-size: 18px, $line-height: 21px, $letter-spacing: 0, $font-weight: normal, $font-style: normal, $font-family: Akkurat Beta Regular, $color: $baad-main-color) {
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-weight: $font-weight;
  font-style: $font-style;
  font-family: $font-family;
  color: $color;
}
