@import "../../assets/styles/_mixins.scss";
@import "../../assets/styles/_variables.scss";

.randomTeaser-page{
    div.randomTip {
        display: flex;
        text-decoration: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &:hover{
            //cursor: url("/fiveone.png") 12 12, auto;
            position: relative;
        }

        .media-style {
            object-fit: cover;
            overflow: hidden;
            width: 100%;
        }

        .page-descrition {
            display: none;
            position: fixed;
            padding: 3px 3px 5px 5px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            background-color: white;
            right: 10px;
            width: 34%;
            animation-delay: 10s;
            font-family: sans-serif;
        }
    }
}

div.randomTeaser-page ~ div#globalCursor {
    position: inherit;
}

.toggle-mute {
    position: absolute;
    z-index: 222;
    bottom: 0;
    width: 123px;
    height: 103px;
    background-position: 50%;
    -webkit-background-size: cover;
    background-size: cover;
    left: 100px;
    cursor: pointer;
}

//TEXT/FONTS:
.randomTeaser-page {
    h1.H1StyleforRandomPage {
        @include baad-text(21px, 21px, 2px, bold);
        margin: 0;
    }
    h3.H3StyleforRandomPage {
        @include baad-text(18px, 21px);
        margin-bottom: 20px;
    }
}
.randomTeaser__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    text-align: center;

    .btn-outlined {
        background: rgba(240, 240, 240, 0.8);
        backdrop-filter: blur(30px);
        min-width: 15.417vw;
        height: 4.688vw;
        font-size: 1.146vw;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        cursor: pointer;
    }
}

@media screen and (max-width: 1230px) {
    .randomTeaser__btn {
        .btn-outlined {
            font-family: "Akkurat Beta Regular";
            min-width: 201px;
            height: 62px;
            font-size: 16px;
        }
    }
}
